import { NgClass, NgIf, UpperCasePipe } from '@angular/common';
import { Component, HostListener, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { GamePremiereComponent } from '@casinocore/platform';
import {
    CasinoLobbyService,
    CasinoManager,
    ConfigProviderService,
    GameLaunchUrlFormationService,
    GamePremiereService,
    GameShowHubService,
    ImmersiveLobbyService,
    ImmersiveLobbySkeletonLoadingConfiguration,
    LiveCasinoLoungeService,
    OpenLoginDialog,
    RecentGamesService,
    TeaserService,
} from '@casinocore/platform/core';
import { addClass } from '@casinocore/platform/utils';
import {
    ClaimsService,
    DeviceService,
    Logger,
    NativeAppService,
    NavigationService,
    TimerService,
    TrackingService,
    UserLoginEvent,
    UserService,
    WindowRef,
} from '@frontend/vanilla/core';
import { PageMatrixComponent } from '@frontend/vanilla/features/content';
import { rxEffects } from '@rx-angular/state/effects';
import { Subscription, filter } from 'rxjs';

import { FeaturesClientConfig } from '../../client-config';
import { ImmersiveLobbySkeletonComponent } from '../../immersive-lobby-skeleton';
import { PerformanceMarker, PerformanceMarkerIdentifiers } from '../../shared/services/performance-marker.service';
import { SkeletonComponent } from '../../skeleton';
import { FullTakeoverBackgroundComponent } from '../full-takeover-background/full-takeover-background.component';
import { WidgetContainerComponent } from '../widget-container';

@Component({
    selector: 'cs-lobby-conatiner',
    templateUrl: 'lobby-container.component.html',
    standalone: true,
    imports: [
        NgIf,
        ImmersiveLobbySkeletonComponent,
        SkeletonComponent,
        NgClass,
        FullTakeoverBackgroundComponent,
        WidgetContainerComponent,
        PageMatrixComponent,
        UpperCasePipe,
    ],
})
export class LobbyContainerComponent implements OnInit, OnDestroy, OnChanges {
    private readonly rxEffects = rxEffects();
    @Input() routeCurrentProduct: string;
    @Input() routeCategoryType: string;
    GameShowLoginButton: string;
    GameShowPopUpLoginContent: string;
    isForceLogin: boolean;
    userName?: string;
    loadingMap: boolean;
    lobbyLoaded: boolean;
    lobbyLoadSubscription: Subscription;
    TeaserDataPublisherSubscription: Subscription;
    currentLobbyType: string;
    lobbyTypeMaps: Map<string, any> = new Map<string, any>();
    skeletonLoadingEnabled: boolean;
    pageNotFound: boolean = false;
    initialScrollTop: number = 0;
    lastScrollTop: number = 0;
    isTopArrowVisible: boolean;
    isRpFavWidgetVisible: boolean;
    isLobbyTransparentBg: boolean;
    isTouch: boolean;
    isLobbyBgclr: boolean;
    gsHubConfig: any;
    liveCasinoLoungeConfig: any;
    immersiveLobbyConfig: any;
    immersiveLobbyLoaded: boolean;
    loadingConfig: ImmersiveLobbySkeletonLoadingConfiguration;
    enableImmersiveLobbySkeletonLoading: boolean;
    private forLiveCasinoLoungeLobby: boolean;
    lmtConfig: any;
    isGameShows: boolean = false;
    private lobbyLoadTrackEventTimer: any;
    isTeasersLoaded: boolean;
    enableGSPreloginPopup: boolean;
    showSkeleton: boolean;
    GameShowRegistrationButton: string;
    gameImpressionConfig: any;
    gamePremiereLoadSubscription: Subscription;
    postloginfirstGamePremiereCall: boolean = false;

    constructor(
        private router: Router,
        private user: UserService,
        private deviceService: DeviceService,
        private casinoManager: CasinoManager,
        private nativeAppService: NativeAppService,
        private performanceMarker: PerformanceMarker,
        private gameShowHubService: GameShowHubService,
        private immersiveLobbyService: ImmersiveLobbyService,
        private casinoLobbyService: CasinoLobbyService,
        private recentGamesService: RecentGamesService,
        private configProviderService: ConfigProviderService,
        private liveCasinoLoungeService: LiveCasinoLoungeService,
        private navigation: NavigationService,
        private featuresConfig: FeaturesClientConfig,
        private windowRef: WindowRef,
        private timerService: TimerService,
        private trackingService: TrackingService,
        private gameLaunchUrlFormationService: GameLaunchUrlFormationService,
        private teaserService: TeaserService,
        private loginDialog: OpenLoginDialog,
        private claimsConfig: ClaimsService,
        private gamePremiereService: GamePremiereService,
        private dialog: MatDialog,
        private logger: Logger,
    ) {}

    ngOnInit() {
        const global = this.configProviderService.provideGlobalConfig();
        if (this.deviceService.model && this.deviceService.model.toLowerCase().indexOf('safari') > -1) addClass('is-safari');

        this.performanceMarker.mark(PerformanceMarkerIdentifiers.FirstMeaningfulPaint);
        this.rxEffects.register(this.casinoLobbyService.categoryNotFoundObservable, (res) => {
            if (res) {
                this.immersiveLobbyLoaded = this.immersiveLobbyLoaded ? false : this.immersiveLobbyLoaded;
                this.pageNotFound = true;
                this.lobbyLoaded = true;
            }
        });

        this.rxEffects.register(
            this.recentGamesService.isRpfavWidgetDisplayedObservable,
            (isRpFavEnabled: boolean) => (this.isRpFavWidgetVisible = isRpFavEnabled),
        );

        this.initialScrollTop = document.documentElement.scrollTop;
        if (this.lmtConfig.enablePostloginPartialCalls) {
            this.rxEffects.register(this.user.events.pipe(filter((e: any) => e instanceof UserLoginEvent)), () => {
                this.casinoLobbyService.setPartialCasinoLobby(this.currentLobbyType);
            });
        }
        this.GameShowPopUpLoginContent = global?.messages?.GameShowPopUpContent;
        this.GameShowLoginButton = global?.messages?.GameShowLogin;
        this.GameShowRegistrationButton = global?.messages?.GameShowRegistration;
        this.setUserName();
    }

    onRegistration(): void {
        const mcPageConfig = this.configProviderService.provideMcPageConfig();
        this.navigation.goTo(mcPageConfig.portalUrl + '/mobileportal/register');
    }

    onLogin(): void {
        this.isForceLogin = true;
        this.loginDialog.open({ loginMessageKey: 'MapLoading' });
    }

    private setUserName(): void {
        this.loadingMap = false;
        if (this.user.isAuthenticated) {
            this.userName = this.claimsConfig.get('givenname') || '';
            this.loadingMap = true;
        } else {
            this.rxEffects.register(this.user.events.pipe(filter((e: any) => e instanceof UserLoginEvent)), () => {
                this.userName = this.claimsConfig.get('givenname') || '';
                this.loadingMap = true;
            });
        }
    }

    ngOnChanges() {
        this.enableGSPreloginPopup = this.configProviderService.provideImmersiveLobbyConfig().enabled
            ? this.configProviderService.provideImmersiveLobbyConfig()?.enableGSPreloginPopup
            : this.configProviderService.provideGameShowHubConfig()?.enableGSPreloginPopup;
        this.isGameShows = this.routeCurrentProduct == 'gameshows';
        this.gameImpressionConfig = this.configProviderService.provideGameImpressionsConfig();
        const currUrl = this.navigation.location.absUrl();
        const hashTextExists = currUrl.includes(this.featuresConfig.hashText);
        if (this.featuresConfig.enableHashText && !hashTextExists) {
            this.navigation.goTo(currUrl + '#' + this.featuresConfig?.hashText, { replace: true });
        }
        this.lmtConfig = this.configProviderService.provideLmtConfig();
        this.gsHubConfig = this.configProviderService.provideGameShowHubConfig();
        this.liveCasinoLoungeConfig = this.configProviderService.provideLiveCasinoLoungeConfig();
        this.immersiveLobbyConfig = this.configProviderService.provideImmersiveLobbyConfig();

        this.unSubscribeLobbyLoadSubscription();
        this.unSubscribeTeaserDataPublisherSubscription();
        this.unSubscribeGamePremiereLoadSubscription();
        this.skeletonLoadingEnabled = true;
        this.forLiveCasinoLoungeLobby = false;
        this.currentLobbyType = this.casinoManager.getLobbyTypeForProduct(this.routeCurrentProduct);

        this.gameLaunchUrlFormationService.setCurrentProduct(this.routeCurrentProduct);

        this.casinoManager.setCurrentLobby(this.currentLobbyType);

        this.casinoLobbyService.setPartialCasinoLobby(this.currentLobbyType);

        this.lobbyLoadSubscription = this.casinoLobbyService.lobbyLoadedObservable.subscribe((response: any) => {
            if (response?.lobbyTypeMaps?.size > 0 && response.lobbyTypeMaps?.get(this.currentLobbyType)?.categoriesMap?.size > 0) {
                this.initLobbyLoadEvent();
                this.lobbyLoaded = true;
                this.pageNotFound = false;
                // Live Casino Lounge
                if (this.forLiveCasinoLoungeLobby) {
                    const defaultCategoryInfo = this.casinoLobbyService.getDefaultOrFirstCategoryInfo(this.currentLobbyType)?.categoryInfo;
                    if (defaultCategoryInfo)
                        this.immersiveLobbyConfig.enabled
                            ? this.setLiveCasinoLoungeLobbyDataLoading(defaultCategoryInfo.categoryid === this.immersiveLobbyConfig.defaultCategoryId)
                            : this.setLiveCasinoLoungeLobbyLoading(defaultCategoryInfo.categoryid === this.liveCasinoLoungeConfig.defaultCategoryId);
                }
                // for game impressions tracking
                if (this.gameImpressionConfig?.isEnabled && (response?.initiateFeeds || response?.isPostLogin)) {
                    this.gameImpressionsTracking(response.lobbyTypeMaps);
                }
                if ((response?.initiateFeeds || response?.isPostLogin) && !this.postloginfirstGamePremiereCall) {
                    this.loadGamePremiereOverlay();
                }
            } else {
                this.lobbyLoaded = false;
            }
        });

        if (!this.currentLobbyType) {
            this.lobbyLoaded = true;
            this.pageNotFound = true;
        } else if (this.immersiveLobbyConfig.enabled) {
            // Gameshow Hub
            if (this.currentLobbyType === this.immersiveLobbyConfig.lobbyType) {
                this.loadingConfig = this.immersiveLobbyConfig.loadingConfig;
                const defaultCategoryInfo = this.casinoLobbyService.getDefaultOrFirstCategoryInfo(this.currentLobbyType)?.categoryInfo;
                if (defaultCategoryInfo && this.routeCurrentProduct == 'livecasino') {
                    this.setLiveCasinoLoungeLobbyDataLoading(defaultCategoryInfo.categoryid === this.immersiveLobbyConfig.defaultCategoryId);
                } else {
                    this.forLiveCasinoLoungeLobby = true;
                    this.setImmersiveLobbyLoading(this.immersiveLobbyService);
                }
            } else {
                this.skeletonLoadingEnabled = true;
                this.immersiveLobbyLoaded = true;
            }
            this.assignFeatures();
        } else {
            // Gameshow Hub
            if (this.currentLobbyType === this.gsHubConfig.lobbyType) {
                this.loadingConfig = this.gsHubConfig.loadingConfig;
                this.setImmersiveLobbyLoading(this.gameShowHubService);
            }
            // Live Casino Lounge
            else if (this.currentLobbyType === this.liveCasinoLoungeConfig.lobbyType && this.liveCasinoLoungeConfig.enabled) {
                const defaultCategoryInfo = this.casinoLobbyService.getDefaultOrFirstCategoryInfo(this.currentLobbyType)?.categoryInfo;
                if (defaultCategoryInfo)
                    this.setLiveCasinoLoungeLobbyLoading(defaultCategoryInfo.categoryid === this.liveCasinoLoungeConfig.defaultCategoryId);
                else this.forLiveCasinoLoungeLobby = true;
            } else {
                this.skeletonLoadingEnabled = true;
                this.immersiveLobbyLoaded = true;
            }
            this.assignFeatures();
        }
        this.TeaserDataPublisherSubscription = this.teaserService.teaserDataPublisher.subscribe((response) => {
            if (response) {
                this.isTeasersLoaded = true;
            }
        });
    }

    private initLobbyLoadEvent() {
        if (!this.casinoLobbyService.isLobbyLoadEventTracked) {
            const featuresConfig = this.configProviderService.provideFeaturesConfig();
            this.lobbyLoadTrackEventTimer = this.timerService.setInterval(() => {
                if (!this.casinoLobbyService.isLobbyLoadEventTracked) {
                    const gtmStartEvent = this.windowRef.nativeWindow['dataLayer']?.find((m: object) => m && m['gtm.start' as keyof typeof m]);
                    if (gtmStartEvent) {
                        this.trackLobbyLoadEvent();
                        this.casinoLobbyService.setLobbyLoadEventTrackingStatus(true);
                        this.clearLobbyLoadTrackEventTimer();
                    }
                }
            }, featuresConfig.gtmCheckInterval || 500);
        }
    }

    private clearLobbyLoadTrackEventTimer(): void {
        this.timerService.clearInterval(this.lobbyLoadTrackEventTimer);
        this.lobbyLoadTrackEventTimer = undefined;
    }

    private trackLobbyLoadEvent(): void {
        const currentLobbyType = this.casinoManager.getLobbyTypeForProduct(this.routeCurrentProduct);
        const defaultCategoryType = this.casinoLobbyService.getDefaultOrFirstCategoryInfo(currentLobbyType)?.categoryInfo;
        const lobbyData: any = this.casinoLobbyService.getLobbyBasedOnLobbyType(this.currentLobbyType);

        this.trackingService.triggerEvent('Event.Tracking', {
            'component.CategoryEvent': currentLobbyType,
            'component.LabelEvent': this.immersiveLobbyConfig.enabled ? 'immersive lobby' : this.routeCategoryType || defaultCategoryType?.categoryid,
            'component.ActionEvent': 'load',
            'component.PositionEvent': 'not applicable',
            'component.LocationEvent': 'not applicable',
            'component.EventDetails': this.routeCategoryType || defaultCategoryType?.categoryid,
            'component.URLClicked': 'not applicable',
            'casino.lobbyName': lobbyData?.lbyTempName ?? null,
            'casino.lobbySource': lobbyData?.lbyTargetType ?? null,
            'casino.optimoveSegment': lobbyData?.optSegName ?? null,
        });

        this.casinoLobbyService.setLobbyLoadEventTrackingStatus(true);
    }

    private setLiveCasinoLoungeLobbyLoading(isDefaultCategory: boolean): void {
        const route = this.router.url;
        const isLiveCasinoLoungeDefaultRoute = route.indexOf('/c/') === -1 && isDefaultCategory;
        const isLiveCasinoLoungeNonDefaultRoute = route.indexOf('/c/') > -1 && route.indexOf(this.liveCasinoLoungeConfig.defaultCategoryId) > -1;
        if (isLiveCasinoLoungeDefaultRoute || isLiveCasinoLoungeNonDefaultRoute) {
            this.loadingConfig = this.liveCasinoLoungeConfig.loadingConfig;
            this.setImmersiveLobbyLoading(this.liveCasinoLoungeService);
        }
    }
    private setLiveCasinoLoungeLobbyDataLoading(isDefaultCategory: boolean): void {
        const route = this.router.url;
        const isLiveCasinoLoungeDefaultRoute = route.indexOf('/c/') === -1 && isDefaultCategory;
        const isLiveCasinoLoungeNonDefaultRoute = route.indexOf('/c/') > -1 && route.indexOf(this.immersiveLobbyConfig.defaultCategoryId) > -1;
        if (isLiveCasinoLoungeDefaultRoute || isLiveCasinoLoungeNonDefaultRoute) {
            this.loadingConfig = this.immersiveLobbyConfig.loadingConfig;
            this.setImmersiveLobbyLoading(this.immersiveLobbyService);
        }
    }

    private setImmersiveLobbyLoading(service: GameShowHubService | LiveCasinoLoungeService | ImmersiveLobbyService) {
        if (service) {
            this.enableImmersiveLobbySkeletonLoading = true;
            this.rxEffects.register(service.loadingObservable, {
                next: (loading: boolean) => (this.immersiveLobbyLoaded = !loading),
                error: () => (this.immersiveLobbyLoaded = true),
            });
        }
    }

    gameImpressionsTracking(lmtData: any) {
        let filteredGameIndex: number = 0;
        let categoryNSubIndex: number = 0;
        let targetedCategoryRoutes = this.gameImpressionConfig?.targetedCategoryRoutes;
        const defaultCategoryType = this.casinoLobbyService.getDefaultOrFirstCategoryInfo(this.currentLobbyType)?.categoryInfo;
        let categoryType = this.routeCategoryType ? this.routeCategoryType : defaultCategoryType?.categoryid;
        const isDefaultCategory = this.casinoLobbyService.isDefaultCategory(this.currentLobbyType, categoryType);
        const disabledGames = this.casinoLobbyService.getDisabledGames();
        const gameImpressions: any = [];
        let lobbyImpressions: LobbyImpressionsModel = new LobbyImpressionsModel();
        const targetedLobbyTypeCatRoutes = targetedCategoryRoutes.filter((e: any) => e.lobbyType == this.currentLobbyType);
        targetedLobbyTypeCatRoutes?.forEach((targetedCategoryRoutes: any) => {
            if (targetedCategoryRoutes?.categoryRoutes.indexOf(categoryType?.toLowerCase()) > -1) {
                categoryNSubIndex = 0;
                let currentCategoryData = lmtData?.get(targetedCategoryRoutes?.lobbyType).categoriesMap.get(categoryType)?.subcategories;
                if (currentCategoryData?.length > 0) {
                    const currentCategory = lmtData?.get(targetedCategoryRoutes?.lobbyType).categoriesMap.get(categoryType);
                    if (!isDefaultCategory && currentCategory?.gamelist?.length > 0) {
                        this.getCategoryData(currentCategory, categoryType, disabledGames, gameImpressions);
                        categoryNSubIndex = categoryNSubIndex + 1;
                    }

                    currentCategoryData?.forEach((catNSubCat: any) => {
                        const addCategory = this.addCategoryInGameImperessionTracking(catNSubCat);
                        if (addCategory) {
                            filteredGameIndex = 0;
                            catNSubCat?.gamelist?.forEach((game: any) => {
                                if (disabledGames.indexOf(game?.game) < 0) {
                                    const gameResultImpObj = this.trackCategoryAndSubCategory(
                                        catNSubCat,
                                        categoryType,
                                        game,
                                        categoryNSubIndex,
                                        filteredGameIndex,
                                    );
                                    gameImpressions.push(gameResultImpObj);
                                    filteredGameIndex++;
                                }
                            });
                            categoryNSubIndex = catNSubCat?.gamelist?.length > 0 ? categoryNSubIndex + 1 : categoryNSubIndex;
                        }
                    });
                } else {
                    const currentCategory = lmtData?.get(targetedCategoryRoutes?.lobbyType).categoriesMap.get(categoryType);
                    this.getCategoryData(currentCategory, categoryType, disabledGames, gameImpressions);
                }
            }
        });

        if (gameImpressions?.length > 0) {
            lobbyImpressions = {
                event: 'gameImpressions',
                game_impressions: gameImpressions,
            };
            this.windowRef.nativeWindow['dataLayer'].push(lobbyImpressions);
        }
    }

    addCategoryInGameImperessionTracking(catNSubCat: any) {
        const slotRacesCategory = this.casinoManager.getSlotRaceCategoryId(this.currentLobbyType);
        const featuresConfig = this.configProviderService.provideFeaturesConfig();
        const isSlotRacesEnabled = this.casinoManager.IsFeatureEnabledforLobbytype(this.currentLobbyType, featuresConfig.enableSlotRacesForLobbyType);
        const isSlotRacesCategory =
            slotRacesCategory == catNSubCat.categoryid || catNSubCat.parentCategoryId + slotRacesCategory == catNSubCat.categoryid;
        if (catNSubCat.isSubCatVisible) {
            if (isSlotRacesEnabled && slotRacesCategory && isSlotRacesCategory) {
                return false;
            }
            return true;
        }
        return false;
    }

    getCategoryData(currentCategory: any, categoryType: string, disabledGames: any, gameImpressions: any[]) {
        let filteredGameIndex: number;
        filteredGameIndex = 0;
        currentCategory?.gamelist?.forEach((game: any) => {
            if (disabledGames?.indexOf(game?.game) < 0) {
                const gameResultImpObj = this.trackCategoryAndSubCategory(currentCategory, categoryType, game, 0, filteredGameIndex);
                gameImpressions.push(gameResultImpObj);
                filteredGameIndex++;
            }
        });
    }

    trackCategoryAndSubCategory(catNSubCat: any, categoryType: string, game: any, catNSubCatIndex: number, gameIndex: number) {
        const gameInfo = this.casinoLobbyService.getGameInfo(game?.game);
        let gameData = game?.game;
        let categorySubType = catNSubCat?.categoryid ? catNSubCat?.categoryid : categoryType;
        let gameName = gameData ? gameData : gameInfo?.name;
        const gameImpressionObj = {
            'component.gameId': categorySubType + '_' + gameName,
            'component.gameDisplayName': gameInfo?.name ? gameInfo.name : gameName,
            'component.gameName': gameName,
            'component.gameType': gameData?.gameType ? gameData?.gameType : gameInfo?.provider,
            'component.Game.section': categoryType,
            'component.categorySubType': categorySubType,
            'component.Game.GridPosition': catNSubCatIndex + 1 + ':' + (gameIndex + 1),
            'component.Game.position': gameIndex + 1,
            'list': 'Casino Games',
        };
        return gameImpressionObj;
    }

    // for back to top arrow functionality

    @HostListener('window:scroll', ['$event'])
    onWindowScroll(event: Event) {
        const scrollElement: any = event.target;
        if (scrollElement.scrollingElement) {
            const scrollTop = scrollElement.scrollingElement.scrollTop;
            //display go to top icon on scrolling up only
            if (window.scrollY && scrollTop && scrollTop > 100 && this.lastScrollTop > scrollTop) {
                this.isTopArrowVisible = true;
            } else if ((this.isTopArrowVisible && window.scrollY) || scrollTop || document.body.scrollTop < 10) {
                this.isTopArrowVisible = false;
            }
            this.lastScrollTop = scrollTop;
        }
        if (this.isRpFavWidgetVisible) {
            if (this.lastScrollTop) {
                dispatchEvent(
                    new CustomEvent('gs:classifyFloatingEntry', {
                        detail: 'casino-gototop-rpfav-enabled',
                    }),
                );
            } else {
                dispatchEvent(
                    new CustomEvent('gs:deClassifyFloatingEntry', {
                        detail: 'casino-gototop-rpfav-enabled',
                    }),
                );
            }
        } else {
            if (this.lastScrollTop) {
                dispatchEvent(
                    new CustomEvent('gs:classifyFloatingEntry', {
                        detail: 'casino-gototop-enabled',
                    }),
                );
            } else {
                dispatchEvent(
                    new CustomEvent('gs:deClassifyFloatingEntry', {
                        detail: 'casino-gototop-enabled',
                    }),
                );
            }
        }
    }

    scrollToTop() {
        const currentScroll = Math.max(window.scrollY || document.documentElement.scrollTop || document.body.scrollTop);
        if (currentScroll > 0) {
            if (this.nativeAppService.isNativeApp || this.nativeAppService.isNativeWrapper) {
                window.scrollTo(0, this.initialScrollTop);
            }
            if (this.isRpFavWidgetVisible) {
                dispatchEvent(
                    new CustomEvent('gs:classifyFloatingEntry', {
                        detail: 'casino-gototop-rpfav-enabled',
                    }),
                );
            } else {
                dispatchEvent(
                    new CustomEvent('gs:deClassifyFloatingEntry', {
                        detail: 'casino-gototop-enabled',
                    }),
                );
            }
        }
    }

    toggleGoToTop() {
        window.scroll(0, 0);
        return false;
    }

    //end top arrow functionality

    assignFeatures() {
        const featuresConfig: any = this.configProviderService.provideFeaturesConfig();
        this.isTouch = this.casinoManager.isTouch();
        this.showSkeleton = featuresConfig.enableSkeleton;
        const isEnableTransparentLobbyBackground: any = this.casinoManager.IsFeatureEnabledforLobbytype(
            this.currentLobbyType,
            featuresConfig.enableTransparentLobbyBackground,
        );
        this.isLobbyTransparentBg =
            (featuresConfig.enableFullTakeOverImageForAllCategories ||
                featuresConfig.enableLobbyStaticImage ||
                featuresConfig.enableLobbyStaticImageForMobile ||
                (featuresConfig.enableLobbyBackgroundImage && !this.isTouch)) &&
            isEnableTransparentLobbyBackground;
        this.isLobbyBgclr =
            featuresConfig.enableFullTakeOverImageForAllCategories ||
            featuresConfig.enableLobbyStaticImage ||
            featuresConfig.enableLobbyStaticImageForMobile ||
            (featuresConfig.enableLobbyBackgroundImage && !this.isTouch);
    }

    loadGamePremiereOverlay() {
        const gamePremiereConfig = this.configProviderService.provideGamePremiereConfig();
        if (this.user.isAuthenticated && gamePremiereConfig?.enableGamePremiere) {
            const defaultCategoryType = this.casinoLobbyService.getDefaultOrFirstCategoryInfo(this.currentLobbyType)?.categoryInfo;
            let categoryType = this.routeCategoryType ? this.routeCategoryType : defaultCategoryType?.categoryid;
            if (this.casinoLobbyService.checkIfGamePremiereEnabledForCategory(this.currentLobbyType, categoryType)) {
                let overlayConfig = gamePremiereConfig?.gamePremiereOverlayConfiguration;
                const statusResponses = gamePremiereConfig?.showOverlyByCampaignStatus;
                this.gamePremiereLoadSubscription = this.gamePremiereService
                    .getGamePremiereUserStatus(overlayConfig.sourceType, overlayConfig.sourceId)
                    .subscribe(
                        (res: any) => {
                            if (res && statusResponses.includes(res.status)) {
                                this.dialog.open(GamePremiereComponent, {
                                    panelClass: ['casino-gamePremiere-overlay'],
                                    disableClose: true,
                                    autoFocus: false,
                                    width: '642px',
                                    height: 'auto',
                                });
                                this.postloginfirstGamePremiereCall = true;
                            }
                        },
                        (error: any) => {
                            this.logger.error('Game premiere POS api fetch failed', error);
                        },
                    );
            }
        }
    }

    unSubscribeLobbyLoadSubscription() {
        if (this.lobbyLoadSubscription) this.lobbyLoadSubscription.unsubscribe();
    }

    unSubscribeTeaserDataPublisherSubscription() {
        if (this.TeaserDataPublisherSubscription) this.TeaserDataPublisherSubscription.unsubscribe();
    }

    unSubscribeGamePremiereLoadSubscription() {
        if (this.gamePremiereLoadSubscription) this.gamePremiereLoadSubscription.unsubscribe();
    }

    ngOnDestroy() {
        this.unSubscribeLobbyLoadSubscription();
        this.unSubscribeTeaserDataPublisherSubscription();
        this.unSubscribeGamePremiereLoadSubscription();
        this.clearLobbyLoadTrackEventTimer();
    }
}

export class LobbyImpressionsModel {
    event: string;
    game_impressions: any;
}
